import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'utils/axios'
import { captureException } from '@sentry/browser'
import { getSlotStatus, SLOT_STATUS, hasAtLeastOneWebexLink as hasAtLeastOneWebexLinkFn } from 'utils/virtualClassroom'
import Scroll from 'react-scroll'
import { FormattedMessage } from 'react-intl'
import {
  useSequenceReducer,
  registerVirtualClassroom,
  unregisterVirtualClassroom,
  confirmPresenceVirtualClassroom,
  refreshSequence,
  initVirtualClassroomSequence,
} from './sequenceReducer'
import { SEQUENCE_ORIGINS } from './constantes'

const SequenceContext = createContext()

const SEQUENCE_TITLE_SCROLL_OFFSET = -12

export const SequenceProvider = ({ active, sequence: sequenceProps, origin, children }) => {
  const [sequence, dispatch] = useSequenceReducer()
  const [updatingSlot, setUpdatingSlot] = useState(false)

  useEffect(() => {
    dispatch(initVirtualClassroomSequence(sequenceProps))
  }, [sequenceProps])

  useEffect(() => {
    if (active) {
      Scroll.scroller.scrollTo(`sequence-${sequenceProps.sequenceNumber}`, {
        duration: 300,
        smooth: true,
        offset: SEQUENCE_TITLE_SCROLL_OFFSET,
      })
    }
  }, [active])

  const comesFromCMSBlock = origin === SEQUENCE_ORIGINS.block
  const sequenceTitlePrefix = (
    <FormattedMessage
      id={`virtual_classroom.label_${comesFromCMSBlock ? 'cms_block' : 'page'}`}
      values={{ number: sequence.sequenceNumber }}
    />
  )

  const register = async virtualClassroom => {
    try {
      setUpdatingSlot(true)
      await axios.post(`/virtualclassroom/${virtualClassroom.id}/confirm-participation`, { origin })
      dispatch(registerVirtualClassroom(virtualClassroom.id))
    } catch (error) {
      const capturedError = error?.response?.data?.detail ?? JSON.stringify(error)
      captureException(new Error(`[virtualClassroom confirm-participation #${virtualClassroom.id}]: ${capturedError}`))
    } finally {
      setUpdatingSlot(false)
    }
  }

  const confirmPresence = async virtualClassroom => {
    try {
      setUpdatingSlot(true)
      await axios.post(`/virtualclassroom/${virtualClassroom.id}/confirm-presence`, { origin })
      dispatch(confirmPresenceVirtualClassroom(virtualClassroom.id))
    } catch (error) {
      const capturedError = error?.response?.data?.detail ?? JSON.stringify(error)
      captureException(new Error(`[virtualClassroom confirm-presence #${virtualClassroom.id}]: ${capturedError}`))
    } finally {
      setUpdatingSlot(false)
    }
  }

  const unregister = async virtualClassroom => {
    try {
      setUpdatingSlot(true)
      await axios.post(`/virtualclassroom/${virtualClassroom.id}/cancel-participation`, { origin })
      dispatch(unregisterVirtualClassroom(virtualClassroom.id))
    } catch (error) {
      const capturedError = error?.response?.data?.detail ?? JSON.stringify(error)
      captureException(new Error(`[virtualClassroom cancel-participation #${virtualClassroom.id}]: ${capturedError}`))
    } finally {
      setUpdatingSlot(false)
    }
  }

  const refresh = () => {
    dispatch(refreshSequence())
  }

  const hasPlannedRegisteredVirtualClassroom = sequence.virtualClassrooms.some(
    virtualClassroom =>
      getSlotStatus(virtualClassroom) === SLOT_STATUS.OPENED && virtualClassroom.hasAuthenticatedParticipantRegistered,
  )

  const hasOnSiteVirtualClassroom = sequence.virtualClassrooms.some(
    virtualClassroom => virtualClassroom.isOnSite === true,
  )

  const hasAtLeastOneWebexLink = hasAtLeastOneWebexLinkFn([sequence])

  return (
    <div id={`sequence-${sequence.sequenceNumber}`}>
      <SequenceContext.Provider
        value={{
          sequence,
          hasOnSiteVirtualClassroom,
          sequenceTitlePrefix,
          hasPlannedRegisteredVirtualClassroom,
          hasAtLeastOneWebexLink,
          register,
          unregister,
          confirmPresence,
          updatingSlot,
          refresh,
          origin,
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {sequence.initialized ? children : null}
      </SequenceContext.Provider>
    </div>
  )
}

SequenceProvider.defaultProps = {
  active: false,
}

SequenceProvider.propTypes = {
  active: PropTypes.bool,
  sequence: PropTypes.shape({
    sequenceNumber: PropTypes.number.isRequired,
    virtualClassrooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  origin: PropTypes.oneOf(Object.values(SEQUENCE_ORIGINS)).isRequired,
  children: PropTypes.node.isRequired,
}

export const useSequenceContext = () => useContext(SequenceContext)
