import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { filter } from 'graphql-anywhere'
import { toInlineFragment } from 'fraql'
import { Row, Col } from 'react-grid-system'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Container } from '@unowmooc/react-ui-kit'
import { mq } from '@unowmooc/themes'
import Helmet from 'components/HelmetIntl'
import PageQuery from 'components/PageQuery'
import UserDetails from './UserDetails'
import KeyNumbers from './KeyNumbers'
import Participations from './Participations'
import Activity from './Activity'

const StyledContainer = styled(Container)`
  margin-top: 110px;
  padding-bottom: 100px;
`

const StyledUserDetails = styled(UserDetails)`
  ${mq.sm(css`
    margin-bottom: 30px;
  `)};
`

const StyledKeyNumbers = styled(KeyNumbers)`
  margin: 20px 0 60px 0;

  ${mq.sm(css`
    margin-bottom: 10px;
  `)};
`

const StyledParticipations = styled(Participations)`
  margin-bottom: 100px;

  ${mq.sm(css`
    margin-bottom: 50px;
  `)};
`

const ProfilePage = ({
  match: {
    params: { id },
  },
}) => (
  <>
    <Helmet title="page_titles.profile.title" />

    <PageQuery
      query={gql`
        query UserProfile($id: ID, $participationFilters: Json) {
          user(id: $id) {
            id
            fullName
            ${toInlineFragment(UserDetails.fragments.user)}
            ${toInlineFragment(Activity.fragments.user)}
            ${toInlineFragment(KeyNumbers.fragments.user)}
          }

          participations: participants(filters: $participationFilters) {
            items {
              ${toInlineFragment(Participations.fragments.participation)}
              ${toInlineFragment(Activity.fragments.participation)}
            }
          }
        }
      `}
      variables={{
        id,
        participationFilters: JSON.stringify({
          user_id: id,
          canceledAt: null,
          and: [{ type: { neq: 'demo' } }, { type: { neq: 'observer' } }],
        }),
      }}
      render={({ user, participations }) => (
        <>
          <Helmet title="page_titles.profile.title_with_name" values={{ name: user.fullName }} />

          <StyledContainer>
            <Row>
              <Col xs={12} md={5} lg={4}>
                <StyledUserDetails user={filter(UserDetails.fragments.user, user)} />
              </Col>
              <Col xs={12} md={7} lg={8}>
                <StyledKeyNumbers user={filter(KeyNumbers.fragments.user, user)} />

                {participations.items.length > 0 && (
                  <StyledParticipations
                    participations={filter(Participations.fragments.participation, participations.items)}
                  />
                )}

                <Activity
                  user={filter(Activity.fragments.user, user)}
                  participations={filter(Activity.fragments.participation, participations.items)}
                />
              </Col>
            </Row>
          </StyledContainer>
        </>
      )}
    />
  </>
)

ProfilePage.propTypes = {
  match: PropTypes.shape().isRequired,
}

export default ProfilePage
