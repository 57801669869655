import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const SlotTitle = ({ title, sequenceNumber, hasOnSiteVirtualClassroom }) => (
  <h3>
    {title ? (
      <>
        {sequenceNumber} - {title}
      </>
    ) : (
      <FormattedMessage
        id={
          hasOnSiteVirtualClassroom
            ? 'guide.slides.virtualClassroom.empty_title_label_on_site'
            : 'guide.slides.virtualClassroom.empty_title_label'
        }
        values={{ sequenceNumber }}
      />
    )}
  </h3>
)

SlotTitle.defaultProps = {
  title: null,
  hasOnSiteVirtualClassroom: false,
}

SlotTitle.propTypes = {
  title: PropTypes.string,
  sequenceNumber: PropTypes.number.isRequired,
  hasOnSiteVirtualClassroom: PropTypes.bool,
}

export default SlotTitle
