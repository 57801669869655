import React from 'react'
import styled from '@emotion/styled'
import I18nProvider from '@unowmooc/i18n'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { filter } from 'graphql-anywhere'
import { LinkButton } from '@unowmooc/react-ui-kit'
import PropTypes from 'prop-types'
import ResponsiveAvatar from 'components/ResponsiveAvatar'
import { colors } from 'styles-resources'
import { useCourseContext } from 'modules/course/components/CourseContext/CourseContext'
import { Link } from 'react-router-dom'
import InfoBloc from '../InfoBloc'

const StyledAvatar = styled(ResponsiveAvatar)`
  margin-right: 10px;
`

const AnimatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  margin-bottom: 10px;
`

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: ${colors.woodsmoke};
  font-size: 14px;
  margin-bottom: 20px;

  > p {
    font-size: 14px;
  }
`

const StyledLinkButton = styled(LinkButton)`
  display: block;
  text-align: center;
`

const getState = (virtualClassroomsNotYetAvailable, hasVirtualClassroomToCome) => {
  if (virtualClassroomsNotYetAvailable) {
    return 'planned'
  }
  return hasVirtualClassroomToCome ? 'inProgress' : 'done'
}

const VirtualClassroom = ({
  sessionIdentifier,
  courseIdentifier,
  animators,
  virtualClassroomsNotYetAvailable,
  hasVirtualClassroomToCome,
  hasOnSiteVirtualClassroom,
}) => {
  const { sessionUrl, hasRSVP } = useCourseContext()

  return (
    <InfoBloc
      title={
        <FormattedMessage
          id={
            hasOnSiteVirtualClassroom
              ? 'courses.course.infos.virtual_classroom.title_on_site'
              : 'courses.course.infos.virtual_classroom.title'
          }
        />
      }
    >
      <AnimationWrapper>
        {animators.map(animator => (
          <AnimatorWrapper key={animator.id}>
            <StyledAvatar size={30} user={filter(ResponsiveAvatar.fragments.user, animator)} />
            <FormattedHTMLMessage
              id="courses.course.infos.virtual_classroom.animate_by"
              values={{ animator: animator.fullName }}
            />
          </AnimatorWrapper>
        ))}
        <p>
          <FormattedMessage
            id={
              hasOnSiteVirtualClassroom
                ? 'courses.course.infos.virtual_classroom.presentation_on_site'
                : 'courses.course.infos.virtual_classroom.presentation'
            }
          />
        </p>
      </AnimationWrapper>
      <StyledLinkButton
        tag={Link}
        to={`${sessionUrl}${I18nProvider.getLinkRoute(`/virtual-classrooms`)}`}
        size="small"
        theme="primary"
        data-tracking-id="virtual_classroom.see_slots"
        data-tracking-values={JSON.stringify({
          courseIdentifier,
          sessionIdentifier,
          state: getState(virtualClassroomsNotYetAvailable, hasVirtualClassroomToCome),
        })}
      >
        <FormattedMessage
          id={`courses.course.infos.virtual_classroom.${
            hasVirtualClassroomToCome || hasRSVP ? 'link' : 'link_all_passed'
          }`}
        />
      </StyledLinkButton>
    </InfoBloc>
  )
}

VirtualClassroom.propTypes = {
  sessionIdentifier: PropTypes.string.isRequired,
  courseIdentifier: PropTypes.string.isRequired,
  animators: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    presentation: PropTypes.string.isRequired,
  }).isRequired,
  virtualClassroomsNotYetAvailable: PropTypes.bool.isRequired,
  hasVirtualClassroomToCome: PropTypes.bool.isRequired,
  hasOnSiteVirtualClassroom: PropTypes.bool.isRequired,
}

export default VirtualClassroom
